const React = require('react');
const ReactDOM = require('react-dom');
const ThemeProvider = require('./src/middleware/ThemeProvider').default;

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 2000);
}

exports.wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
