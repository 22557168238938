import { lightTheme } from '../theme';
import { darkTheme } from '../theme';

export function getThemeByName(theme) {
  return themeMap[theme];
}

const themeMap = {
  lightTheme,
  darkTheme,
};
