import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { getThemeByName } from './themechanger';

export const ThemeContext = React.createContext<(themeName: string) => void>((themeName: string) => {});

const ThemeProvider = props => {
  const [themeName, _setThemeName] = useState<string>('lightTheme');

  const theme = getThemeByName(themeName);

  return (
    <ThemeContext.Provider value={_setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
