import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createSpacing from '@material-ui/core/styles/createSpacing';
import './assets/fonts.css';

const brand = {
  typography: {
    primary: {
      regular: "'CabinetGrotesk-Regular', 'Helvetica', 'Arial', sans-serif",
      bold: "'CabinetGrotesk-Bold', 'Helvetica', 'Arial', sans-serif",
      extraBold: "'CabinetGrotesk-Extrabold', 'Helvetica', 'Arial', sans-serif",
      black: "'CabinetGrotesk-Black', 'Helvetica', 'Arial', sans-serif",
      medium: "'CabinetGrotesk-Medium', 'Helvetica', 'Arial', sans-serif",
    },
    secondary: {
      regular: "'Roboto-Regular', sans-serif",
    },
  },
  swatches: {
    white: '#fff',
    redPepper: '#db3012',
    blackPepperDarker: '#151008',
    redPepperDarker: 'rgb(159, 35, 13)',
    darkWhite: '#fafafa',
    gray: 'rgba(21, 16, 8, 0.6)',
  },
};

const breakpoints = createBreakpoints({});
const spacing = createSpacing(8);

const mixins = {
  toolbar: {
    [breakpoints.up('md')]: {
      minHeight: '8rem',
    },
  },
};

const lightPalette = {
  type: 'light',
  primary: {
    main: brand.swatches.redPepper,
  },
  text: {
    primary: brand.swatches.blackPepperDarker,
    secondary: brand.swatches.white,
  },
  background: {
    default: brand.swatches.white,
  },
  white: brand.swatches.white,
  blackPepperDarker: brand.swatches.blackPepperDarker,
  redPepperDarker: brand.swatches.redPepperDarker,
  darkWhite: brand.swatches.darkWhite,
  gray: brand.swatches.gray,
  redPepper: brand.swatches.redPepper,
};

const darkPalette = {
  type: 'dark',
  primary: {
    main: brand.swatches.redPepper,
  },
  background: {
    default: brand.swatches.blackPepperDarker,
  },
  white: brand.swatches.white,
  blackPepperDarker: brand.swatches.blackPepperDarker,
  redPepperDarker: brand.swatches.redPepperDarker,
};

const props = {
  // Name of the component ⚛️
  MuiButtonBase: {
    // The properties to apply
    disableRipple: true, // No more ripple, on the whole application 💣!
  },
  MuiButton: {
    disableElevation: true,
  },
};

const typography = {
  fontFamily: brand.typography.primary.regular,
  h1: {
    fontFamily: brand.typography.primary.black,
    fontSize: '8.125rem',
    lineHeight: 1,
    textTransform: 'uppercase',

    [breakpoints.down('sm')]: {
      fontSize: '3.5rem',
    },
  },
  h2: {
    fontFamily: brand.typography.primary.black,
    fontSize: '6rem',
    lineHeight: 1,
  },
  h3: {
    fontFamily: brand.typography.primary.black,
    fontSize: '3rem',
  },
  h4: {
    fontFamily: brand.typography.primary.extraBold,
    fontSize: '2.8125rem',
    lineHeight: 1.1,
  },
  h5: {
    fontFamily: brand.typography.primary.extraBold,
    fontSize: '1.6875rem',
  },
  h6: {
    fontFamily: brand.typography.primary.extraBold,
    fontSize: '0.75rem',
  },
  body1: {
    fontFamily: brand.typography.secondary.regular,
    fontSize: '1.25rem',
    fontWeight: '300',
  },
  body2: {
    fontFamily: brand.typography.secondary.regular,
    fontSize: '1rem',
    fontWeight: '300',
  },
  subtitle1: {
    /*quote*/ fontFamily: brand.typography.primary.bold,
    fontSize: '5.25rem',
    lineHeight: 1.2,

    [breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
  subtitle2: {
    /*lead*/ fontFamily: brand.typography.primary.medium,
    fontSize: '3rem',
    lineHeight: 1.45,
  },
  button: {
    fontFamily: brand.typography.primary.medium,
    fontSize: '2.25rem',
  },
  quote: {
    fontFamily: 'monospace',
  },
};

const overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
    textSizeLarge: {
      padding: spacing(2, 5),
      fontSize: '2.25rem',
    },
    iconSizeLarge: {
      '& > *:first-child': {
        fontSize: '2.25rem',
      },
    },
  },
  MuiContainer: {
    root: {
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(6),
        paddingRight: spacing(6),
      },
    },
  },
  MuiToolbar: {
    root: {
      [breakpoints.down('sm')]: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
    },
    gutters: {
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(6),
        paddingRight: spacing(6),
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: brand.swatches.gray,
    },
  },
};

export const lightTheme = responsiveFontSizes(
  createTheme({
    palette: lightPalette,
    breakpoints: breakpoints,
    mixins: mixins,
    overrides: overrides,
    props: props,
    typography: typography,
    spacing: spacing,
  })
);

export const darkTheme = responsiveFontSizes(
  createTheme({
    palette: darkPalette,
    breakpoints: breakpoints,
    mixins: mixins,
    overrides: overrides,
    props: props,
    typography: typography,
    spacing: spacing,
  })
);
